export const headers = {
  'Content-Type': 'application/json',
  'api-key': process.env.REACT_APP_API_KEY
}
export const getLogosApiUrl = (user_id, type) => {
  return process.env.REACT_APP_API_URL + `/api/select/${user_id}/images?imagetype=${type}`
}
export const uploadImageUrl = () => {
  return process.env.REACT_APP_API_URL + '/api/insert/images'
}
export const updateImageUrl = (image_id) => {
  return process.env.REACT_APP_API_URL + `/api/update/${image_id}/images`
}
export const savePdfUrl = () => {
  return process.env.REACT_APP_API_URL + '/api/insert/my_references'
}
export const savedReferencePdfUrl = (user_id) => {
  return process.env.REACT_APP_API_URL + `/api/select/${user_id}/my_references`
}
export const getUserIdFromLocalStorage = () => {
  const userID = localStorage.getItem('user_id')

  if (userID) {
    return Number(userID)
  }
}
export const uploadPiUrl = () => {
  return process.env.REACT_APP_API_URL + '/api/insert/pi'
}
export const getMyPiUrl = (user_id) => {
  return process.env.REACT_APP_API_URL + `/api/select/${user_id}/pi`
}
export const getMyPiReplaceUrl = (pi_id) => {
  return process.env.REACT_APP_API_URL + `/api/update/${pi_id}/pi`
}
export const uploadIsifileUrl = () => {
  return process.env.REACT_APP_API_URL + '/api/insert/isi'
}
export const getIsifileUrl = (user_id) => {
  return process.env.REACT_APP_API_URL + `/api/select/${user_id}/isi`
}
export const getIsiDetailApiUrl = (user_id, isi_id) => {
  return process.env.REACT_APP_API_URL + `/api/select/${user_id}/isi?isi_id=${isi_id}`
}
export const uploadQrUrl = () => {
  return process.env.REACT_APP_API_URL + '/api/insert/qr_code'
}
export const getQrApiUrl = (user_id) => {
  return process.env.REACT_APP_API_URL + `/api/select/${user_id}/qr_code`
}
export const getProjectList = (user_id) => {
  return process.env.REACT_APP_API_URL + `/api/select_projects_with_versions/${user_id}`
}
export const getProjectVersionsList = (user_id, project_id) => {
  return process.env.REACT_APP_API_URL + `/api/select_project_with_versions/${user_id}/${project_id}`
}
export const getSaveProjectVersionAPIURL = (user_id, project_id) => {
  return process.env.REACT_APP_API_URL + `/api/create_version/${user_id}/${project_id}`
}
export const getDeleteProjectVersionAPIURL = (user_id, project_id, version_id) => {
  return process.env.REACT_APP_API_URL + `/api/delete_version/${user_id}/${project_id}/${version_id}`
}
export const getDeleteProjectAPIURL = (user_id, project_id) => {
  return process.env.REACT_APP_API_URL + `/api/delete_project/${user_id}/${project_id}`
}
export const getDisableProjectAPIURL = (user_id, project_id) => {
  return process.env.REACT_APP_API_URL + `/api/update_project_status/${user_id}/${project_id}`
}
export const getDisableProjectVersionAPIURL = (user_id, project_id, version_id) => {
  return process.env.REACT_APP_API_URL + `/api/update_version_status/${user_id}/${project_id}/${version_id}`
}
// export const getCategories = (parent_category_id) => {
//   parent_category_id = parent_category_id || 0
//   return process.env.REACT_APP_API_URL + `/api/select_application/app_categories/?searchvalues=${parent_category_id}`
// }

//Templates api

export const getCategories = () => {
  return process.env.REACT_APP_API_URL + `/api/select_application/app_categories/`
}
export const getAppTypes = () => {
  return process.env.REACT_APP_API_URL + `/api/select_application/app_types`
}
export const uploadTemplates = () => {
  return process.env.REACT_APP_API_URL + `/api/insert/templates`
}
export const uploadToTemplateCategory = () => {
  return process.env.REACT_APP_API_URL + `/api/insert/template_categories`
}
export const getAllTemplates = () => {
  return (
    process.env.REACT_APP_API_URL +
    `/api/select_application/templates
  `
  )
}
export const fetchTemplatebyType = (template_type) => {
  return process.env.REACT_APP_API_URL + `/api/select_template/?searchvalue=${template_type}`
}

// export const filterTemplate = (type, category_id, type_id) => {
//   const apiUrl = process.env.REACT_APP_API_URL
//   const searchValue = encodeURIComponent(type)
//   const categoryParam = category_id !== '' ? `&category=${category_id}` : ''
//   const typeParam = type_id !== '' ? `&type=${type_id}` : ''
//   return `${apiUrl}/api/select_template?searchvalue=${searchValue}${categoryParam}${typeParam}`

// }
export const filterTemplate = (type, category_id, type_id) => {
  const apiUrl = process.env.REACT_APP_API_URL
  const searchValue = type.toLowerCase() !== 'all' ? `searchvalue=${encodeURIComponent(type)}` : ''
  const categoryParam = category_id !== '' ? `&category=${category_id}` : ''
  const typeParam = type_id !== '' ? `&type=${type_id}` : ''

  return `${apiUrl}/api/select_template?${searchValue}${categoryParam}${typeParam}`
}

export const updateColorPallete = (color_palettes_id) => {
  return process.env.REACT_APP_API_URL + `/api/update/${color_palettes_id}/color_palettes`
}
export const updateFonts = (font_id) => {
  return process.env.REACT_APP_API_URL + `/api/update/${font_id}/fonts `
}

export const checkImageSize = (size) => {
  const imageSize = Number(size)
  const maxSize = 50 * 1024 * 1024
  if (imageSize > maxSize) {
    return false
  } else {
    return true
  }
}
export const checkFileSize = (size) => {
  const fileSize = Number(size)
  const maxSize = 50 * 1024 * 1024
  if (fileSize > maxSize) {
    return false
  } else {
    return true
  }
}
export const checkImageLimit = (count) => {
  const imageCount = 50

  if (imageCount == count || imageCount < count) {
    return false
  } else {
    return true
  }
}
export const checkFileLimit = (count) => {
  const fileCount = 50

  if (fileCount == count || fileCount < count) {
    return false
  } else {
    return true
  }
}
