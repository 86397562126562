import React, { useEffect, useState } from 'react'
import '../../myfont/index.scss'
import FontPicker from 'font-picker-react'
import AdjustFontComponent from './AdjustFontComponent'
import PreviewFontComponent from './PreviewFontComponent'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import { insertFonts } from './utilities/fonts'
import { getUserIdFromLocalStorage, updateFonts, headers } from 'config/apiConfig'
import axios from 'axios'

const MyFont = ({ savedFonts, onSave, initData, handleDeleteConfirmation }) => {
  const [activeheadlineFontFamily, setActiveheadlineFontFamily] = useState(initData?.font_headline?.activeheadlineFontFamily || 'Open Sans')
  const [activesubheadlineFontFamily, setActivesubheadlineFontFamily] = useState(
    initData?.font_sub_headline?.activesubheadlineFontFamily || 'Open Sans'
  )
  const [activebodylineFontFamily, setActivebodylineFontFamily] = useState(initData?.font_body?.activebodylineFontFamily || 'Open Sans')

  const [headfontSize, setHeadFontSize] = useState(initData?.font_headline?.headfontSize || 24) // Initial font size
  const [isheadUnderlined, setHeadIsUnderlined] = useState(initData?.font_headline?.isheadUnderlined || false)
  const [isheadStrikedOut, setHeadIsStrikedOut] = useState(initData?.font_headline?.isheadStrikedOut || false)

  const [subheadfontSize, setSubHeadFontSize] = useState(initData?.font_sub_headline?.subheadfontSize || 20) // Initial font size
  const [issubheadUnderlined, setSubHeadIsUnderlined] = useState(initData?.font_sub_headline?.issubheadUnderlined || false)
  const [issubheadStrikedOut, setSubHeadIsStrikedOut] = useState(initData?.font_sub_headline?.issubheadStrikedOut || false)

  const [bodyfontSize, setBodyFontSize] = useState(initData?.font_body?.bodyfontSize || 16) // Initial font size
  const [isbodyUnderlined, setBodyIsUnderlined] = useState(initData?.font_body?.isbodyUnderlined || false)
  const [isbodyStrikedOut, setBodyIsStrikedOut] = useState(initData?.font_body?.isbodyStrikedOut || false)

  const [resetForm, setResetForm] = useState(false)
  const [receivedHeadData, setReceivedHeadData] = useState('')
  const [receivedSubHeadData, setReceivedSubHeadData] = useState('')
  const [receivedBodyData, setReceivedBodyData] = useState('')
  const [fontname, setFontName] = useState(initData?.font_name || '')
  const userId = getUserIdFromLocalStorage()

  const maxFonts = 4
  const sendheadData = JSON.stringify(receivedHeadData)
  const sendsubheadData = JSON.stringify(receivedSubHeadData)
  const sendbodyData = JSON.stringify(receivedBodyData)

  useEffect(() => {
    if (resetForm) {
      setActiveheadlineFontFamily('Open Sans')
      setActivesubheadlineFontFamily('Open Sans')
      setActivebodylineFontFamily('Open Sans')
      setHeadFontSize(24)
      setHeadIsUnderlined(false)
      setHeadIsStrikedOut(false)
      setSubHeadFontSize(20)
      setSubHeadIsUnderlined(false)
      setSubHeadIsStrikedOut(false)
      setBodyFontSize(16)
      setBodyIsUnderlined(false)
      setBodyIsStrikedOut(false)
    }
  }, [resetForm])
  const handleheadlineFontChange = (nextFont) => {
    setActiveheadlineFontFamily(nextFont.family)
  }
  const handlesubheadlineFontChange = (nextFont) => {
    setActivesubheadlineFontFamily(nextFont.family)
  }
  const handlebodylineFontChange = (nextFont) => {
    setActivebodylineFontFamily(nextFont.family)
  }
  useEffect(() => {
    handleHeadDataUpdate([
      {
        activeheadlineFontFamily,
        headfontSize,
        isheadUnderlined,
        isheadStrikedOut
      }
    ])
  }, [activeheadlineFontFamily, headfontSize, isheadUnderlined, isheadStrikedOut])

  useEffect(() => {
    handleSubHeadDataUpdate([
      {
        activesubheadlineFontFamily,
        subheadfontSize,
        issubheadUnderlined,
        issubheadStrikedOut
      }
    ])
  }, [activesubheadlineFontFamily, subheadfontSize, issubheadUnderlined, issubheadStrikedOut])

  useEffect(() => {
    handleBodyDataUpdate([
      {
        activebodylineFontFamily,
        bodyfontSize,
        isbodyUnderlined,
        isbodyStrikedOut
      }
    ])
  }, [activebodylineFontFamily, bodyfontSize, isbodyUnderlined, isbodyStrikedOut])

  const increaseHeadFontSize = () => {
    setHeadFontSize(headfontSize + 2) // Increase font size by 2 units
  }
  const decreaseHeadFontSize = () => {
    if (headfontSize > 6) {
      setHeadFontSize(headfontSize - 2) // Decrease font size by 2 units, with a minimum of 6px
    }
  }
  const toggleHeadUnderline = () => {
    setHeadIsUnderlined(!isheadUnderlined)
  }
  const toggleHeadStrikeout = () => {
    setHeadIsStrikedOut(!isheadStrikedOut)
  }

  const increaseSubHeadFontSize = () => {
    setSubHeadFontSize(subheadfontSize + 2) // Increase font size by 2 units
  }
  const decreaseSubHeadFontSize = () => {
    if (subheadfontSize > 6) {
      setSubHeadFontSize(subheadfontSize - 2) // Decrease font size by 2 units, with a minimum of 6px
    }
  }
  const toggleSubHeadUnderline = () => {
    setSubHeadIsUnderlined(!issubheadUnderlined)
  }
  const toggleSubHeadStrikeout = () => {
    setSubHeadIsStrikedOut(!issubheadStrikedOut)
  }

  const increaseBodyFontSize = () => {
    setBodyFontSize(bodyfontSize + 1) // Increase font size by 2 units
  }
  const decreaseBodyFontSize = () => {
    if (bodyfontSize > 6) {
      setBodyFontSize(bodyfontSize - 1) // Decrease font size by 2 units, with a minimum of 6px
    }
  }
  const toggleBodyUnderline = () => {
    setBodyIsUnderlined(!isbodyUnderlined)
  }
  const toggleBodyStrikeout = () => {
    setBodyIsStrikedOut(!isbodyStrikedOut)
  }

  const headTextStyle = {
    fontSize: `${headfontSize}px`,
    textDecoration: `${isheadUnderlined ? 'underline' : ''} ${isheadStrikedOut ? 'line-through' : ''}`
  }
  const subheadTextStyle = {
    fontSize: `${subheadfontSize}px`,
    textDecoration: `${issubheadUnderlined ? 'underline' : ''} ${issubheadStrikedOut ? 'line-through' : ''}`
  }
  const bodyTextStyle = {
    fontSize: `${bodyfontSize}px`,
    textDecoration: `${isbodyUnderlined ? 'underline' : ''} ${isbodyStrikedOut ? 'line-through' : ''}`
  }

  const handleHeadDataUpdate = (headData) => {
    setReceivedHeadData(headData)
  }
  const handleSubHeadDataUpdate = (subheadData) => {
    setReceivedSubHeadData(subheadData)
  }
  const handleBodyDataUpdate = (bodyData) => {
    setReceivedBodyData(bodyData)
  }
  const handleResetForm = () => {
    setResetForm(true)
    setTimeout(() => {
      setResetForm(false)
    }, 0)
  }

  const saveFonts = async () => {
    if (!fontname.trim()) {
      toast.error('Font name is not allowed to be empty.')
      return
    }

    if (savedFonts.length === maxFonts && !initData) {
      toast.error(' You have reached the maximum limit. You are allowed to create only 4 Fonts.')
      return
    }

    const datafonts = {
      user_id: userId,
      font_name: fontname,
      font_headline: sendheadData,
      font_sub_headline: sendsubheadData,
      font_body: sendbodyData
    }

    try {
      let response

      if (initData) {
        // If initData exists, it means we are in update mode
        response = await axios.post(updateFonts(initData.font_id), datafonts, { headers })
      } else {
        // If initData doesn't exist, it means we are in save mode
        response = await insertFonts(datafonts, { headers }) // Use the insertFonts function for creating new fonts
        setFontName('')
        handleResetForm()
      }

      if (response.status === 200) {
        console.log(initData ? 'Font updated successfully.' : 'Font saved successfully.')
        toast.success(initData ? 'Font updated successfully.' : 'Font saved successfully.')
        onSave()
      } else {
        console.error(initData ? 'Failed to update Fonts data.' : 'Failed to save Fonts data.')
        toast.error(initData ? 'An error occurred while updating Fonts data' : 'An error occurred while saving Fonts data')
      }
    } catch (error) {
      console.error('An error occurred while saving Fonts data:', error)
      toast.error('An error occurred while saving Fonts data')
    }
  }

  return (
    <>
      <div className='myfont-header'>
        {!initData && <p className='create-new-color'>Create a New Font</p>}

        <input
          type='text'
          placeholder='Font Name'
          value={fontname}
          onChange={(e) => {
            setFontName(e.target.value)
          }}
        />
      </div>
      <div className='myfont'>
        <div className='right-container-fonts'>
          <p className='heading'>Headline Font</p>
          <FontPicker
            pickerId={`headline${initData?.font_id}`}
            apiKey='AIzaSyBbcQm-5iS3Tsrw1yWHLaB9PshzDrbAsnA'
            activeFontFamily={activeheadlineFontFamily}
            onChange={handleheadlineFontChange}
          />
          <AdjustFontComponent
            decreaseFontSize={decreaseHeadFontSize}
            fontSize={headfontSize}
            increaseFontSize={increaseHeadFontSize}
            isUnderlined={isheadUnderlined}
            isStrikedOut={isheadStrikedOut}
            toggleUnderline={toggleHeadUnderline}
            toggleStrikeout={toggleHeadStrikeout}
          />
          <p className='heading'>Sub Headline Font</p>
          <FontPicker
            pickerId={`subheadline${initData?.font_id}`}
            apiKey='AIzaSyBbcQm-5iS3Tsrw1yWHLaB9PshzDrbAsnA'
            activeFontFamily={activesubheadlineFontFamily}
            onChange={handlesubheadlineFontChange}
          />
          <AdjustFontComponent
            decreaseFontSize={decreaseSubHeadFontSize}
            fontSize={subheadfontSize}
            increaseFontSize={increaseSubHeadFontSize}
            isUnderlined={issubheadUnderlined}
            isStrikedOut={issubheadStrikedOut}
            toggleUnderline={toggleSubHeadUnderline}
            toggleStrikeout={toggleSubHeadStrikeout}
          />
          <p className='heading '>Body Copy Font</p>
          <FontPicker
            pickerId={`bodyline${initData?.font_id}`}
            apiKey='AIzaSyBbcQm-5iS3Tsrw1yWHLaB9PshzDrbAsnA'
            activeFontFamily={activebodylineFontFamily}
            onChange={handlebodylineFontChange}
          />
          <AdjustFontComponent
            decreaseFontSize={decreaseBodyFontSize}
            fontSize={bodyfontSize}
            increaseFontSize={increaseBodyFontSize}
            isUnderlined={isbodyUnderlined}
            isStrikedOut={isbodyStrikedOut}
            toggleUnderline={toggleBodyUnderline}
            toggleStrikeout={toggleBodyStrikeout}
          />
        </div>
        <PreviewFontComponent
          headStyle={headTextStyle}
          subheadStyle={subheadTextStyle}
          bodyStyle={bodyTextStyle}
          fontId={initData?.font_id}
        />
      </div>
      <div className='update-delete-button'>
        <div className='save_div_row font'>
          <button type='button' onClick={saveFonts}>
            {initData ? 'Update' : 'Save'}
          </button>

          {initData && (
            <button className='update-button' onClick={handleDeleteConfirmation}>
              Delete
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default MyFont

MyFont.propTypes = {
  savedFonts: PropTypes.array,
  onSave: PropTypes.func,
  toggleUnderline: PropTypes.func,
  toggleStrikeout: PropTypes.func,
  initData: PropTypes.object,
  handleDeleteConfirmation: PropTypes.func
}
