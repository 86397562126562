import {
  getLogosApiUrl,
  headers,
  uploadImageUrl,
  getUserIdFromLocalStorage,
  savePdfUrl,
  updateImageUrl,
  savedReferencePdfUrl,
  uploadPiUrl,
  getMyPiUrl,
  getMyPiReplaceUrl,
  uploadIsifileUrl,
  getIsifileUrl,
  getIsiDetailApiUrl,
  getAllTemplates,
  fetchTemplatebyType,
  uploadQrUrl,
  getQrApiUrl
} from 'config/apiConfig'
import { deleteFroms3, getObjectURL, uploadTos3 } from './s3Helper'
import axios from 'axios'
import { formatDate } from './dateHelper'

export async function getImageListApi(type) {
  const user_id = getUserIdFromLocalStorage()
  try {
    const response = await axios.get(getLogosApiUrl(user_id, type), { headers })

    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (imageObj) => {
          const presignedUrl = process.env.REACT_APP_BUCKET_BASE_URL + imageObj.image_url

          return { ...imageObj, image_url: presignedUrl }
        })
      )

      return updatedData
    } else {
      // Handle the case where no records are found
      return [] // You can return an empty array or handle this case as needed
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}
export async function handleImageUpload(file, type, image_id) {
  const user_id = getUserIdFromLocalStorage()
  if (file) {
    const uniqueFileName = `${Math.floor(Date.now() / 1000)}-${file.name}`
    const key = `${user_id}/mbl/${type}/${uniqueFileName}`

    return await uploadTos3(file, key).then(async () => {
      const body = {
        user_id: user_id,
        image_name: file.name,
        image_url: key,
        image_type: type,
        image_size: file.size,
        image_extension: file.name.split('.').pop()
      }

      const response = await axios.post(image_id ? updateImageUrl(image_id) : uploadImageUrl(), body, { headers })

      return response
    })
  }
}

export async function deleteImage(image_id, key) {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/delete/' + image_id + '/images', { headers: headers })

    // If the Axios request was successful, attempt to delete the image from S3
    try {
      await deleteFroms3(key)
    } catch (s3Error) {
      console.error('Error deleting from S3:', s3Error)
    }

    return response
  } catch (axiosError) {
    console.error('Error in Axios request:', axiosError)
    throw axiosError // Re-throw the error to be handled by the calling code
  }
}

// pdf uploader

export async function handlePdfUpload(file, pdfData, type) {
  const user_id = getUserIdFromLocalStorage()
  if (file) {
    const uniqueFileName = `${Math.floor(Date.now() / 1000)}-${file.name}`
    const key = `${user_id}/mbl/${type}/${uniqueFileName}`
    const fileExtension = file.name.split('.').pop() // Get the file extension
    const data = {
      user_id: user_id,
      file_name: file.name.replace(new RegExp(`\\.${fileExtension}$`), ''), // Remove the extension from the filename
      file_url: key,
      file_size: file.size,
      file_extension: fileExtension
    }
    if (pdfData.author_name) {
      data.author_name = pdfData.author_name
    }
    if (pdfData.publication_date) {
      data.publication_date = pdfData.publication_date
    }
    if (pdfData.claim) {
      data.claim = pdfData.claim
    }
    if (pdfData.annotation) {
      data.annotation = pdfData.annotation
    }
    if (pdfData.citation) {
      data.citation = pdfData.citation
    }
    return await uploadTos3(file, key).then(async () => {
      const response = await axios.post(savePdfUrl(), data, { headers })
      return response
    })
  } else {
    const data = {
      user_id: user_id
    }
    if (pdfData.author_name) {
      data.author_name = pdfData.author_name
    }
    if (pdfData.publication_date) {
      data.publication_date = pdfData.publication_date
    }
    if (pdfData.claim) {
      data.claim = pdfData.claim
    }
    if (pdfData.annotation) {
      data.annotation = pdfData.annotation
    }
    if (pdfData.citation) {
      data.citation = pdfData.citation
    }
    const response = await axios.post(savePdfUrl(), data, { headers })
    return response
  }
}

// ReferenceForm component remains the same

export async function getPdfList(type, userId, searchvalue, searchby) {
  try {
    // Create the URL with searchvalue and searchby as query parameters
    const url = savedReferencePdfUrl(userId, type) + `?searchvalue=${searchvalue}&searchby=${searchby}`
    const response = await axios.get(url, { headers })
    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (fileObj) => {
          if (!fileObj.file_url) {
            return fileObj
          } else {
            const presignedUrl = process.env.REACT_APP_BUCKET_BASE_URL + fileObj.file_url

            return { ...fileObj, file_url: presignedUrl }
          }
        })
      )
      return updatedData
    } else {
      return []
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}
// end of pdf

export async function handleIsiUpload(file, isiPdf, filename, type) {
  const user_id = getUserIdFromLocalStorage()
  if (file) {
    const uniqueFileName = `${Math.floor(Date.now() / 1000)}-${file.name}`
    const key = `${user_id}/mbl/${type}/${uniqueFileName}`

    return await uploadTos3(file, key).then(async () => {
      const data = {
        user_id: user_id,
        file_name: filename,
        file_url: key,
        file_size: file.size,
        file_extension: file.name.split('.').pop()
      }

      if (isiPdf.indication) {
        data.indication = isiPdf.indication
      }

      if (isiPdf.important_safety_information) {
        data.important_safety_information = isiPdf.important_safety_information
      }

      if (isiPdf.warnings_precautions) {
        data.warnings_precautions = isiPdf.warnings_precautions
      }

      if (isiPdf.adverse_reactions) {
        data.adverse_reactions = isiPdf.adverse_reactions
      }

      if (isiPdf.please_see) {
        data.please_see = isiPdf.please_see
      }

      const response = await axios.post(uploadIsifileUrl(), data, { headers })
      return response
    })
  }
}

/* ISI File fetch*/
export async function getMyIsiList() {
  const user_id = getUserIdFromLocalStorage()
  try {
    const response = await axios.get(getIsifileUrl(user_id), { headers })
    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (fileObj) => {
          const presignedUrl = process.env.REACT_APP_BUCKET_BASE_URL + fileObj.file_url
          return { ...fileObj, file_url: presignedUrl }
        })
      )
      return updatedData
    } else {
      // Handle the case where no records are found
      return [] // You can return an empty array or handle this case as needed
    }
  } catch (error) {
    console.error('Error making GET request:', error)
    throw error
  }
}

//For psi file upload
export async function handlePiFileUpload(file, type, pi_id, filename) {
  const user_id = getUserIdFromLocalStorage()
  if (file) {
    const uniqueFileName = `${Math.floor(Date.now() / 1000)}-${file.name}`
    const key = `${user_id}/mbl/${type}/${uniqueFileName}`
    return await uploadTos3(file, key).then(async () => {
      const body = {
        user_id: user_id,
        file_name: filename,
        file_url: key,
        file_size: file.size,
        file_extension: file.name.split('.').pop()
      }

      const response = await axios.post(pi_id ? getMyPiReplaceUrl(pi_id) : uploadPiUrl(), body, { headers })
      console.log(body, 'eee')
      console.log(response, 'eee')
      return response
    })
  }
}
export async function getMyPiList() {
  const user_id = getUserIdFromLocalStorage()
  try {
    const response = await axios.get(getMyPiUrl(user_id), { headers })

    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (fileObj) => {
          const presignedUrl = process.env.REACT_APP_BUCKET_BASE_URL + fileObj.file_url
          const formattedDate = await formatDate(fileObj.uploaded_date)
          return { ...fileObj, file_url: presignedUrl, uploaded_date: formattedDate }
        })
      )

      return updatedData
    } else {
      // Handle the case where no records are found
      return [] // You can return an empty array or handle this case as needed
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}

export async function getIsiDetailsApi(isi_id) {
  const user_id = getUserIdFromLocalStorage()
  try {
    const response = await axios.get(getIsiDetailApiUrl(user_id, isi_id), { headers })

    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (fileObj) => {
          const presignedUrl = process.env.REACT_APP_BUCKET_BASE_URL + fileObj.file_url

          const formattedDate = await formatDate(fileObj.last_updated)
          return { ...fileObj, file_url: presignedUrl, last_updated: formattedDate }
        })
      )

      return updatedData
    } else {
      // Handle the case where no records are found
      return [] // You can return an empty array or handle this case as needed
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}

//templates api helper
export async function fetchAllTemplates() {
  try {
    const response = await axios.get(getAllTemplates(), { headers })
    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (tempObj) => {
          const s3Key = `base-template/${tempObj.template_slug}/${tempObj.template_thumbnail_url}`
          // const s3Key = tempObj.template_thumbnail_url
          const presignedUrl = await getObjectURL(s3Key)

          return { ...tempObj, template_thumbnail_url: presignedUrl }
        })
      )

      return updatedData
    } else {
      return []
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}

export async function getAllTemplatesByType(type) {
  try {
    const response = await axios.get(fetchTemplatebyType(type), { headers })
    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (tempObj) => {
          const presignedUrl =
            process.env.REACT_APP_BUCKET_BASE_URL + `templates/${tempObj.template_slug}/${tempObj.template_thumbnail_url}`

          return { ...tempObj, template_thumbnail_url: presignedUrl }
        })
      )

      return updatedData
    } else {
      return []
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}
export async function deleteColorPallete(color_palettes_id) {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/delete/' + color_palettes_id + '/color_palettes', {
      headers: headers
    })
    // If the Axios request was successful, attempt to delete the image from S3
    return response
  } catch (axiosError) {
    console.error('Error in Axios request:', axiosError)
    throw axiosError // Re-throw the error to be handled by the calling code
  }
}
export async function deletemyFont(font_id) {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + '/api/delete/' + font_id + '/fonts', {
      headers: headers
    })
    // If the Axios request was successful, attempt to delete the image from S3
    return response
  } catch (axiosError) {
    console.error('Error in Axios request:', axiosError)
    throw axiosError // Re-throw the error to be handled by the calling code
  }
}
export async function handleQrUpload(value, file, type) {
  const user_id = getUserIdFromLocalStorage()
  if (file) {
    const uniqueFileName = `${Math.floor(Date.now() / 1000)}-qrcode`
    const key = `${user_id}/mbl/qr/${uniqueFileName}`

    return await uploadTos3(file, key).then(async () => {
      const body = {
        user_id: user_id,
        qr_code_name: value,
        qr_code_url: key,
        qr_code_extension: type
      }

      const response = await axios.post(uploadQrUrl(), body, { headers })

      return response
    })
  }
}
export async function getQrListApi() {
  const user_id = getUserIdFromLocalStorage()
  try {
    const response = await axios.get(getQrApiUrl(user_id), { headers })

    if (response.data && response.data.length > 0) {
      const updatedData = await Promise.all(
        response.data.map(async (qrObj) => {
          const presignedUrl = process.env.REACT_APP_BUCKET_BASE_URL + qrObj.qr_code_url

          const image = qrObj.qr_code_extension === 'svg' ? await (await fetch(presignedUrl)).text() : presignedUrl
          return { ...qrObj, qr_code_url: image }
        })
      )

      return updatedData
    } else {
      // Handle the case where no records are found
      return [] // You can return an empty array or handle this case as needed
    }
  } catch (error) {
    console.error('Error making GET request:', error)
  }
}
